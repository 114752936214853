.moveable {
  position: absolute;
  z-index: 9;
  border: 1px solid #d3d3d3;
  text-align: center;
  background-color: #151515;
  min-width: 500px;
  min-height: 500px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  display: none;
}

/* Style for the resize handles */
.resize-handle {
  position: absolute;
  background: transparent;
  z-index: 10;
}

.resize-handle.top {
  height: 10px;
  cursor: ns-resize;
  top:0;
  width: 100%;
}
.resize-handle.bottom {
  bottom: 0;
  width: 100%;
  height: 10px;
  cursor: ns-resize;
}

.resize-handle.left {
  height: 100%;
  left: 0;
  width: 10px;
  cursor: ew-resize;
}

.resize-handle.right {
  height: 100%;
  right: 0;
  width: 10px;
  cursor: ew-resize;
}

.resize-handle.top-left,
.resize-handle.bottom-right {
  width: 10px;
  height: 10px;
  cursor: nwse-resize;
}

.resize-handle.top-right,
.resize-handle.bottom-left{
  width: 10px;
  height: 10px;
  cursor: nesw-resize;
}

.resize-handle.top-left {
  left: 0;
  top: 0;
}

.resize-handle.top-right {
  right: 0;
  top: 0;
}

.resize-handle.bottom-left {
  left: 0;
  bottom: 0;
}

.resize-handle.bottom-right {
  right: 0;
  bottom: 0;
}
.moveableheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #000;
  color: #fff;
  font-size: 10px;
  text-align: left;
}
.moveable.active {
  border: 2px solid #892637;
}


.close-button {
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  padding: 0 8px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.close-button:hover {
  background-color: #e4767e;
}

.Main {
  width: 500px;
  height: 500px;

}

body {
  color: #fff;
}

img {
  width: auto;
  height: auto;
}