@import url('https://fonts.googleapis.com/css2?family=Oxygen+Mono&display=swap');

body {
  background-color: #303030;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Oxygen Mono', monospace;
  overflow: hidden;
  min-height: 100vh; /* Ensure body takes at least full viewport height */
  position: relative; /* Ensure positioning context for the pseudo-element */
}

